import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext.js";
import Zaslepka from "./Pages/Zaslepka/Index";

import ScrollToTop from "./ScrollToTop.js";
const LandingPage = React.lazy(() => import("./Pages/LandingPage/Index.js"));

const Login = React.lazy(() => import("./Pages/Accounts/Login/Index.js"));
// const Register = React.lazy(() => import("./pages/Accounts/Register/Index.js"));

const ForgetPassword = React.lazy(() =>
  import("./Pages/Accounts/ForgetPassword/Index.js")
);
const Admin = React.lazy(() => import("./Pages/Admin/Home/Index.js"));
const AdminTerminy = React.lazy(() => import("./Pages/Admin/Terminy/Index.js"));

function App() {
  const authCtx = useContext(UserContext);
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* CORE */}
          {/* Accounts */}
          <Route path="/zaloguj-sie" element={<Login />} />
          <Route path="/przypomnij-haslo" element={<ForgetPassword />} />
          <Route path="/" element={<LandingPage />} />
          {authCtx.isLoggedIn && (
            <>
              {/* ADMIN */}
              <Route index path="/admin" element={<Admin />} />
              <Route index path="/admin/terminy" element={<AdminTerminy />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
